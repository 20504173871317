@media(min-width:868px) and (max-width: 1199px){
    .my_container{position: relative;margin: 0 auto;width: 868px;z-index: 1;}
    .nav-floor{width: 868px !important}
}
@media(min-width: 1200px) and (max-width: 1365px){
    .my_container{position: relative;margin: 0 auto;width: 1093px;z-index: 1;}
    .nav-floor{width: 1068px !important}
}
@media(min-width: 1366px) and (max-width: 1599px){
    .my_container{position: relative;margin: 0 auto;width: 1268px;z-index: 1;}
    .nav-floor{width: 1268px !important}
}
@media(min-width: 1600px) and (max-width: 1919px){
    .my_container{position: relative;margin: 0 auto;width: 1330px;z-index: 1;}
    .nav-floor{width: 1468px !important}
}
@media(min-width:1920px){
    .my_container{position: relative;margin: 0 auto;width: 1430px;z-index: 1;}
    .nav-floor{width: 1430px !important}
}
@primary-color: #1f2774;