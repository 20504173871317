// @rem: 1rem/16;
@import url('./vars.less');

html {
  font-size: 16px;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  // color: #fff;
  background-color: @background;
  // 禁止下拉刷新
  overscroll-behavior-y: contain;
}

//滚动条消失
// ::-webkit-scrollbar {
//   display: none;
// }

div {
  box-sizing: border-box;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.xy50 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scrollY {
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrollX {
  overflow-x: scroll;
  overflow-y: hidden;
}

a {
  text-decoration: none;
  color: #333;
}

// a:hover,
// a:visited,
// a:link,
// a:active {
//   color: #333;
// }

// 设置使所有a标签的四种状态都和本身颜色保持一致，样式代码自己写

// 超过当前div宽度省略...
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my_loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.my_flex {
  display: flex;
  align-items: center;
}

// @media (max-width: 480px) {
//     html {
//         font-size: 8px;
//     }
// }

// @media (min-width: 480px) {
//     html {
//         font-size: 11px;
//     }
// }

// @media (min-width: 992px) {
//     html {
//         font-size: 12px;
//     }
// }

// @media (min-width: 1200px) {
//     html {
//         font-size: 13px;
//     }
// }

// @media (min-width: 1400px) {
//     html {
//         font-size: 14px;
//     }
// }
@primary-color: #1f2774;